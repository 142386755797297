<template>
  <div
    class="layout-default"
  >
    <BlockLayoutHeader v-bind="headerProps" />

    <ClientOnly>
      <Teleport to="body">
        <Transition name="fade-in">
          <AtomPopoverNotification
            v-if="popupVisible"
            position="fixed"
            :text="popupMessage"
            :type="popupType"
            @on-hide="hidePopup()"
          />
        </Transition>
      </Teleport>
    </ClientOnly>

    <BlockTresPurpletable
      :upload-art-button-text="tableSettings?.uploadArtButtonText"
      :upload-selfie-button-text="tableSettings?.uploadSelfieButtonText"
      :overlay-text="tableSettings?.overlayText"
      :overlay-selfie-text="tableSettings?.overlaySelfieText"
      :overlay-title="tableSettings?.overlayTitle"
      :selected-overlay-text="tableSettings?.selectedOverlayText"
      :selected-overlay-title="tableSettings?.selectedOverlayTitle"
      :upload-art-link="tableSettings?.uploadArtLink"
      :upload-selfie-link="tableSettings?.uploadSelfieLink"
      :enter-animation="runEnterAnimation"
      @on-ressources-loaded="onRessourcesLoaded()"
    />

    <Suspense>
      <slot />
    </Suspense>

    <BlockOverlayEnteranimation
      :is-open="!sceneReady"
      @on-close="runEnterAnimation = true"
    />

    <AtomCursorText :is-visible="true" />
  </div>
</template>

<script setup>
const { locale } = useI18n();

const headerSettings = computed(() => getStoryblokSetting('navigation'));
const headerProps = computed(() => {
    if (!headerSettings.value) return {};
    return {
        title: headerSettings.value.title,
        logo: headerSettings.value.logo,
        hex: headerSettings.value.hex,
        buttons: headerSettings.value.buttons.map((button) => ({
            text: button.label,
            link: useMapStoryblokLink(button.link, locale.value),
        })),
    };
});

const tableSettings = computed(() => getStoryblokSetting('table'));

/*
    Scene ready state
*/
const runEnterAnimation = shallowRef(false);
const sceneReady = shallowRef(false);
const onRessourcesLoaded = () => {
    sceneReady.value = true;
};
</script>
<style lang="scss" scoped>
.layout-default {
    min-height: 100dvh;
    padding-top: var(--header-height);
    padding-bottom: 60px;
}
</style>
