<template>
  <ClientOnly>
    <div
      :style="computedStyle"
      class="atom-cursor-text"
      :class="{
        'is-hidden': !cursorIsVisible || isOutside,
        'has-text-content': text,
      }"
    >
      <div class="atom-cursor-text__line-wrapper">
        <div class="atom-cursor-text__line" />
        <div class="atom-cursor-text__line" />
        <div class="atom-cursor-text__line" />
        <div class="atom-cursor-text__line" />
        <div class="atom-cursor-text__line" />

        <div
          class="atom-cursor-text__pin"
          type="button"
          @click="onClick"
          @keypress="onClick"
        >
          <span class="atom-cursor-text__text">{{ text }}</span>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
defineProps({
    text: {
        type: String,
        default: 'Hier hochladen',
    },

    borderRadius: {
        type: String,
        default: 'var(--b-radius--full)',
    },
});

const { x: mouseX, y: mouseY } = useMouse({ touch: false, type: 'client' });
const { width, height } = useWindowSize();

const hideSpace = 4;
const isOutside = ref(false);

const cursorX = ref(mouseX.value);
const cursorY = ref(mouseY.value);

watch([mouseX, mouseY], () => {
    const hideCursor = mouseX.value >= width.value - hideSpace
        || mouseX.value <= hideSpace
        || mouseY.value >= height.value - hideSpace
        || mouseY.value <= hideSpace;

    isOutside.value = hideCursor;
});

let animationFrameId;

const updateCursor = () => {
    cursorX.value = mouseX.value;
    cursorY.value = mouseY.value;
    animationFrameId = requestAnimationFrame(updateCursor);
};

onMounted(() => {
    animationFrameId = requestAnimationFrame(updateCursor);
});

onUnmounted(() => {
    cancelAnimationFrame(animationFrameId);
});

const computedStyle = computed(() => ({
    top: `${cursorY.value - 2}px`,
    left: `${cursorX.value - 1}px`,
}));
</script>

<style lang="scss" scoped>
.atom-cursor-text {
    @include z-index('cursorText');

    --transition-duration: 0.4s;
    --transition-delay: 0s;
    position: fixed;
    display: none;
    width: 3px;
    height: 3px;
    border-radius: var(--b-radius--full);
    background: var(--c-white);
    font-family: var(--f-family--primary);
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    pointer-events: none;
    text-align: center;
    transition: width var(--transition-duration) var(--ease-out--back), height var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    @include only-hover {
        display: block;
    }

    &.has-transition-delay {
        --transition-delay: 0.6s;
    }

    &.is-hidden {
        width: 0;
        height: 0;
        transition-delay: 0s;
    }
}

.atom-cursor-text__line-wrapper {
    position: absolute;
    right: 0;
    bottom: 1.5px;
    left: 0;
    display: flex;
    width: 1px;
    height: 16px;
    flex-direction: column;
    margin: auto;
    row-gap: 1px;
    transition: height var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    .has-text-content & {
        height: 20px;
    }

    .is-hidden & {
        height: 0;
        transition-delay: 0s;

        .has-text-content & {
            height: 0;
        }
    }

}

.atom-cursor-text__line {
    flex-basis: 100%;
    border-radius: var(--b-radius--full);
    background: var(--c-white);
}

.atom-cursor-text__pin {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    overflow: hidden;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    border: 4px solid #DE8ED942;
    border-radius: v-bind(borderRadius);
    margin: auto;
    background-color: var(--c-white);
    cursor: pointer;
    pointer-events: all;
    transform: translateX(-50%) translateY(-100%);
    transition: width var(--transition-duration) var(--ease-out--back), height var(--transition-duration) var(--ease-out--back), border-radius var(--transition-duration) var(--ease-out--back),border-width var(--transition-duration) var(--ease-out--back);
    transition-delay:var(--transition-delay);

    .is-hidden & {
        width: 0 !important;
        height: 0 !important;
        border-width: 0 !important;
        transition-delay: 0s;
    }

    .has-text-content & {
        width: 100px;
        height: 24px;
        flex-wrap: nowrap;
        padding: 0 5px;
        white-space: nowrap;
    }

    @include only-hover {
        &:hover {
            width: 53px;
            height: 53px;

            .has-text-content & {
                width: unset;
                flex-wrap: nowrap;
                white-space: nowrap;
            }

            .atom-cursor-text__image {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

.atom-cursor-text__text {
    padding: 5px;
    opacity: 1;
    pointer-events: none;
    transition: opacity var(--transition-duration) var(--ease-out--quart);
    transition-delay:var(--transition-delay);

    .is-hidden & {
        opacity: 0;
        transition-delay: 0s;
    }
}

</style>
