export const useMapStoryblokLink = (link, locale) => {
    const { cached_url: cachedUrl } = link;

    const alternates = link?.story?.alternates;
    let translatedSlug = null;

    if (alternates && locale) {
        const alternate = alternates.find((alt) => alt.lang === locale);
        translatedSlug = alternate ? `/${alternate.lang}/${alternate.path}` : null;
    }

    const translatedUrl = translatedSlug || cachedUrl;
    const replacedPath = translatedUrl.replace(`/${locale.value}`, '');

    let urlString = replacedPath === '/home' ? '/' : translatedUrl;
    urlString = urlString[0] === '/' ? urlString.substr(1) : urlString;

    const linkPrefix = link.linktype === 'email' ? 'mailto:' : '';
    const linkSuffix = link.anchor ? `#${link.anchor}` : '';
    const linkSlash = link.linktype !== 'email'
    && !urlString.includes('http://')
    && !urlString.includes('https://') ? '/' : '';

    return `${linkSlash}${linkPrefix}${urlString}${linkSuffix}`;
};

export const useMapStoryblokLinkComponents = (links) => links.map((component) => {
    const { link } = component;

    return {
        link: useMapStoryblokLink(link),
        text: component.label,
        _uid: component._uid,
    };
});
