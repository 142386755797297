<template>
  <div class="block-layout-header">
    <div class="block-layout-header__wrapper">
      <div class="block-layout-header__inner">
        <UtilRouteLink href="/" class="block-layout-header__group">
          <span class="block-layout-header__home-link">{{ title }}</span>
          <span>{{ hex }}</span>
        </UtilRouteLink>
        <div class="block-layout-header__group is-image">
          <AtomImage
            v-if="logo.filename"
            :image="logo"
            scaling-direction="height"
            class="block-layout-header__logo"
          />
        </div>
        <div class="block-layout-header__group is-buttons">
          <template v-if="!hasCloseButton">
            <UtilRouteLink
              v-for="button in buttons"
              :key="button.link"
              :href="button.link"
            >
              <AtomButton

                :text="button.text"
              />
            </UtilRouteLink>
          </template>

          <UtilRouteLink v-if="hasCloseButton" href="/">
            <div class="atom-close">
              <IonIcon

                icon-color="var(--c-primary)"
                icon-width="16px"
                icon-name="close"
              />
            </div>
          </UtilRouteLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    hex: {
        type: String,
        default: '',
    },

    logo: {
        type: Object,
        default: () => {},
    },

    buttons: {
        type: Array,
        default: () => [],
    },
});

const route = useRoute();
const hasCloseButton = computed(() => route.path !== '/' && route.path !== '/home');
</script>

<style lang="scss" scoped>
.block-layout-header {
    @include fluid-simple('padding-top', 32px, 52px);
    @include fluid-simple('padding-bottom', 32px, 52px);
    @include fluid-simple('font-size', 14px, 24px);
    @include z-index('header');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: var(--page-padding);
    padding-left: var(--page-padding);
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-weight: var(--f-weight--800);
    pointer-events: none;
}

.block-layout-header__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 43px;
}

.block-layout-header__group {
    display: flex;
    flex-basis: calc(100% / 2);
    align-items: center;

    &.is-buttons {
        column-gap: 20px;
    }

    @include tablet {
        flex-basis: calc(100% / 3);
    }

    & > * {
        pointer-events: all;
    }

    &:first-child {
        @include fluid-simple('column-gap', 8px, 26px);
        flex-basis: 70%;

        @include tablet {
            flex-basis: calc(100% / 3);
        }
    }

    &:nth-child(2) {
        flex-basis: 30%;
        justify-content: flex-end;

        @include tablet {
            flex-basis: calc(100% / 3);
            justify-content: center;
        }
    }

    &:nth-child(3) {
        display: flex;
        min-height: 45px;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 20px;

        @include tablet {
            flex-basis: calc(100% / 3);
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}

.block-layout-header__logo {
    @include fluid-simple('height', 14px, 20px);
}

.atom-close {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--c-secondary);
    cursor: pointer;
    transition: transform 0.3s ease;

    @include only-hover {
        &:hover {
            transform: scale(1.05) rotate(90deg);
        }
    }
}
</style>
