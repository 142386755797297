<template>
  <div
    ref="wrapperRef"
    class="block-overlay-enteranimation"
  >
    <div
      ref="animationRef"
      class="block-overlay-enteranimation__inner"
    >
      #ThePurpleTable
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: true,
    },
});

const { isOpen } = toRefs(props);
const { gsap } = useGsap();

const animationRef = shallowRef(null);
const wrapperRef = shallowRef(null);
const firstAnimationDone = shallowRef(false);
const emit = defineEmits(['on-close']);

const animateOut = () => {
    if (firstAnimationDone.value) {
        emit('on-close');

        gsap.to(wrapperRef.value, {
            duration: 1.5,
            autoAlpha: 0,
            scale: 1.5,
            ease: 'power3.inOut',
        });
    }
};

watch(() => isOpen.value, (newValue) => {
    if (!newValue) {
        animateOut();
    }
}, { immediate: true });

onMounted(async () => {
    await sleep(10);
    gsap.to(animationRef.value, {
        duration: 1.5,
        autoAlpha: 1,
        scale: 1,
        ease: 'power3.out',
        onComplete: () => {
            firstAnimationDone.value = true;
            animateOut();
        },
    });
});
</script>

<style lang="scss" scoped>
.block-overlay-enteranimation {
    @include z-index('enterAnimation');
    @include fluid-simple('font-size', 30px, 100px);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: var(--c-primary--light);
    color: var(--c-primary);
    font-family: var(--f-family--primary);
    font-weight: var(--f-weight--800);
}

.block-overlay-enteranimation__inner {
    transform: scale(0);
}
</style>
